<template>
 <!-- 发票抬头列表页 -->
 <div class="invoiceTitle" >
	 <van-nav-bar
	   title="发票抬头"
	   left-arrow
	   @click-left="$router.go(-1)"
	    @click-right="deleteTitleList"
	 >
	 <template #right>
	     <van-icon v-if="$route.query.id" name="delete-o" size="18" />
	   </template>
	   <!--   :right-text="$route.query.id?'删除':''" -->
	 </van-nav-bar>
	<!-- right-text="选择抬头" -->
	<van-form @submit="onSubmit">
	  <van-cell-group>
		  <van-field 
		   :required="true"
		    :rules="[{ required: true, message: '请选择抬头类型' }]"
		   label="抬头类型">
		    <template #input>
		      <van-radio-group v-model="formObj.riseType" direction="horizontal">
		        <van-radio :name="1">企业单位</van-radio>
		        <van-radio :name="2">个人/非企业单位</van-radio>
		      </van-radio-group>
		    </template>
		  </van-field>
		  
		  
	    <template v-if="formObj.riseType==1" key="form_obj_a" >
			<van-field
			  v-model="formObj.riseName"
			  label="公司名称"
			  placeholder="公司名称"
			   :required="true"
			  :rules="[{ required: true, message: '请填写公司名称' }]"
			/>
			<van-field
			  v-model="formObj.corporateNumber"
			  label="公司税号"
			  placeholder="公司税号"
			   :required="true"
			  :rules="[{ required: true, message: '请填写公司税号' }]"
			/>
			<van-field
			  v-model="formObj.corporateAddress"
			  label="公司地址"
			  placeholder="公司地址"
			/>
			<van-field
			  v-model="formObj.corporatePhone"
			  label="公司电话"
			  placeholder="公司电话"
			   type="tel"
			/>
			<van-field
			  v-model="formObj.corporateBank"
			  label="公司开户行"
			  placeholder="公司开户行"
			/>
			<van-field
			  v-model="formObj.corporateAccount"
			  label="开户行账号"
			  placeholder="开户行账号"
			/>
		</template>
		
		<template v-else-if="formObj.riseType==2" key="form_obj_b" >
			<van-field
			  v-model="formObj.riseName"
			  label="姓名"
			  placeholder="姓名"
			   :required="true"
			  :rules="[{ required: true, message: '请填写姓名' }]"
			/>
		</template>
		
	  </van-cell-group>
	  <div style="margin: 16px;">
	    <van-button :loading="isLoading.onSubmit"  round block type="primary" native-type="submit">
	      {{$route.query.id?"修改":"提交"}}
	    </van-button>
	  </div>
	</van-form>
	
 </div>
</template>

<script>
import { NavBar,Form,CellGroup,Field,RadioGroup,Radio,Button, Toast,Icon } from "vant";

export default {
  name: "invoice",
  components: {
	  [NavBar.name]:NavBar,
	  [Form.name]:Form,
	  [CellGroup.name]:CellGroup,
	  [Field.name]:Field,
	  [RadioGroup.name]:RadioGroup,
	  [Radio.name]:Radio,
	  [Button.name]:Button,
	  [Icon.name]:Icon,
  },
  provide() {
    return {};
  },
  data() {
    return {
		formObj:{
			riseType:1,
		},
		isLoading:{}
	};
  },
  mixins: [],
  watch: {},
  created() {
	  this.getInfo();
  },
  mounted() {},
  methods: {
	  // 获取信息
	  getInfo(){
		 if(this.$route.query.id){
			 this.$http.request("getRiseInfo",{
				 id:this.$route.query.id
			 }).then((res)=>{
				 this.formObj=res||{};
				 console.log(this.formObj)
			 }).catch(()=>{})
			 
		 } 
	  },
	  // 提交、修改
	  onSubmit(){
		  if(this.isLoading.onSubmit){
			  return;
		  }
		  this.isLoading.onSubmit=true;
		  let u="";
		  let f={}
		  if(this.formObj.riseType==1){
			  f={
			  			  patientId: this.$cache.local.getItem("patientId"),
			  			  corporateAccount  : this.formObj.corporateAccount,
			  			  corporateAddress : this.formObj.corporateAddress,
			  			  corporateBank  : this.formObj.corporateBank,
			  			  corporateNumber  : this.formObj.corporateNumber,
			  			  corporatePhone : this.formObj.corporatePhone,
			  			  riseName  : this.formObj.riseName,
			  			  riseType : this.formObj.riseType,
			  }
		  }else if(this.formObj.riseType==2){
			  f={
			  			  patientId: this.$cache.local.getItem("patientId"),
			  			  riseName  : this.formObj.riseName,
			  			  riseType : this.formObj.riseType,
			  }
		  }
		  if(this.$route.query.id){
			  // 修改
			  f.id=this.$route.query.id
			  u="riseModify"
		  }else{
			  // 新增
			  u="riseAdd"
		  }
		  this.$http.request(u,f)
		  .then((res)=>{
			   Toast({
			  type:"success",
			  message:`${this.$route.query.id?'修改':'新增'}成功`,
			  onClose:()=>{
				  this.isLoading.onSubmit=false;
				  this.$router.go(-1)
			  }
		  })
		  })
		  // .catch(()=>{})
		 
		  
	  },
	  // 删除
	  deleteTitleList(){
		  if(!this.$route.query.id){
			  return;
		  }
		  if(this.isLoading.deleteTitleList){
			  return;
		  }
		  this.isLoading.deleteTitleList=true;
		  // id
		  this.$http.request("riseDelete",{id:this.$route.query.id})
		  .then((res)=>{
			   Toast({
			  type:"success",
			  message:"删除成功",
		  })
		  this.$router.go(-1)
		  })
		  .catch(()=>{})
		 
		  
	  },
  },
  beforeUnmount() {},
  // beforeRouteLeave(to,from,next){},
};
</script>
<style scoped lang="scss">
.invoiceTitle{
	height: 100vh;
}
.list{
	height: calc(100% - 50px);
	overflow: auto;
	.item{
		border-bottom: 1px solid #ccc;
		padding: 14px;
		.name{
			font-size: 14px;
			line-height: 30px;
			font-weight: 600;
			padding-bottom: 10px;
		}
		.type{
			font-size: 14px;
		}
	}
}
</style>
